<template>
  <div :class="selected? 'cell-star-select':''">
    <div
      class="cell-star"
      @click="toStarPage"
    >
      <van-radio-group
        v-model="selected"
        @click.stop="select"
        @change="handleChange"
      >
        <van-radio name="1">
          &nbsp;
        </van-radio>
      </van-radio-group>
      <img
        class="avatar"
        :src="data && data.avatar ? data.avatar:require('@/assets/images/icon-avatar@2x.png')"
      >
      <div class="common-info">
        <div>
          <span class="nickname">{{ data && data.nickname ? data.nickname : '-' }}</span>
          <img
            class="icon-gender"
            src="@/assets/images/icon-male@2x.png"
            v-if="data && data.gender === 1"
          >
          <img
            class="icon-gender"
            src="@/assets/images/icon-female@2x.png"
            v-else-if="data && data.gender === 2"
          >
        </div>
        <div class="brief">
          <span>ID: {{ data ? (data.short_id ? data.short_id : data.id) : '-' }}</span>
          <span class="mcn">MCN：{{ data && data.mcn && data.mcn.name ? data.mcn.name : '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSelectCellStarInfo',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      selected: false
    };
  },
  watch: {},
  methods: {
    select() {

    },
    handleChange() {
      if (!this.selected) {
        return;
      }
      this.$emit('selectChange', this.data.id);
    },
    toStarPage() {
      console.log('to star page');
    }
  },
};
</script>

<style lang="less" scoped>
.cell-star {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 40px;
  border-bottom: 1px solid #E0E0E0;

  .avatar {
    flex-shrink: 0;
    width: 108px;
    height: 108px;
    border-radius: 60px;
    margin-left: 20px;
  }

  .common-info {
    width: 100%;
    margin-left: 30px;

    .nickname {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
    }

    .icon-gender {
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }

    .brief {
      font-size: 24px;
      font-weight: 400;
      color: #999;
      line-height: 32px;
      margin-top: 20px;
    }

    .mcn {
      margin-left: 20px;
    }
  }
}

.cell-star-select {
  background: #EFF8FF;
}
</style>