<template>
  <div class="select-star">
    <div class="search">
      <van-field
        class="search-input"
        v-model="value"
        placeholder="请输入达人名称"
        @update:model-value="inputChange"
      />
      <van-button
        class="search-button"
        type="primary"
        :disabled="searchDisabled"
        @click="toQueryStar(keyword)"
      >
        搜索
      </van-button>
    </div>
    <div
      class="star-list"
      v-if="stars && stars.length > 0"
    >
      <single-select-cell-star-info
        v-for="star in stars"
        :ref="'star_'+star.id"
        :data="star"
        :key="star.id"
        @selectChange="handleStarSelectChange"
      />
    </div>
    <van-loading
      class="loading"
      type="spinner"
      color="#1989fa"
      size="24"
      v-else-if="searching"
    />
    <empty
      v-else
    />
  </div>
</template>

<script>
import Empty from '@/components/Empty';
import SingleSelectCellStarInfo from './components/SingleSelectCellStarInfo';
import { mapActions } from 'vuex';
import * as api from '@/api/session';

export default {
  name: 'SelectStar',
  components: {
    Empty,
    SingleSelectCellStarInfo,
  },
  data() {
    return {
      searching: false,
      keyword: undefined,
      value: '',
      searchDisabled: true,
      stars: [],
      selectedStar: undefined,
    };
  },
  watch: {
    stars(newValue, oldValue) {
      if (!this.selectedStar) {
        return;
      }
      let selectedStarExists = false;
      this.stars.forEach((x) => {
        if (x.id === this.selectedStar.id) {
          selectedStarExists = true;
        }
      });
      console.log('selectedStarExists:' + selectedStarExists);
      if (!selectedStarExists) {
        this.selectedStar = undefined;
      }
    },
  },
  methods: {
    inputChange(val) {
      this.searchDisabled = !(val && val.length > 0);
      this.keyword = val;
      if (val && val.length > 0) {
        this.toQueryStar(val);
      } else {
        this.stars = [];
      }
    },
    handleStarSelectChange(id) {
      this.stars.forEach((x) => {
        if (x.id === id) {
          this.selectedStar = x;
        } else {
          this.$refs['star_' + x.id][0].selected = false;
        }
      });
    },
    async toQueryStar(nickname) {
      this.searching = true;
      try {
        const res = await this.queryTiktokStar({ nickname });
        if (this.keyword !== nickname) {
          return;
        }
        this.stars = res.data;

      } catch (err) {
      } finally {
        this.searching = false;
      }
    },
    ...mapActions({
      queryTiktokStar:
        'star/queryTiktokStar',
    }),
  }
};
</script>

<style lang="less" scoped>
.select-star {
  .search {
    position: relative;
    padding: 40px 30px;
    border-bottom: 1px solid #E0E0E0;

    .search-input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: #F7F7F7;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 65px;
      border: 1px solid #E6E6E6;
      //font-size: 30px;
    }

    .search-button {
      position: absolute;
      top: 40px;
      right: 30px;
      width: 140px;
      height: 80px;
      border-radius: 65px;
    }
  }
}
.loading {
  position: absolute;
  left: calc(50% - 12px);
  top: 600px;
  z-index: 9999;
}
</style>